import {} from '../context/cart/graphql';

import { ProductOperations } from '../interfaces/operations/productOperations';

import { ApolloCache, ApolloClient } from '@apollo/client';
import {
    ProductsPriceAndInventoriesDocument,
    ProductsPriceAndInventoriesQuery,
    ProductsPriceAndInventoriesQueryVariables,
} from '../context/store/graphql';

export class MagentoProductOperations implements ProductOperations {
    private apolloClient: ApolloClient<object>;
    private updateApolloCache: (cache: ApolloCache<any>) => void;

    private cache: Record<
        string,
        {
            data:
                | NonNullable<
                      ProductsPriceAndInventoriesQuery['products']
                  >['items']
                | undefined;
            timestamp: number;
        }
    > = {};

    private cacheExpirationTime = 60 * 1000;

    constructor(
        apolloClient: ApolloClient<object>,
        updateApolloCache: (cache: ApolloCache<any>) => void
    ) {
        this.apolloClient = apolloClient;
        this.updateApolloCache = updateApolloCache;
    }

    public async getPricesAndInventoriesQuery(
        skus: string[]
    ): Promise<
        | NonNullable<ProductsPriceAndInventoriesQuery['products']>['items']
        | undefined
    > {
        const cacheKey = skus.sort().join(',');

        const cachedEntry = this.cache[cacheKey];
        const now = Date.now();

        if (
            cachedEntry &&
            now - cachedEntry.timestamp < this.cacheExpirationTime
        ) {
            return cachedEntry.data;
        }

        const result = await this.apolloClient.query<
            ProductsPriceAndInventoriesQuery,
            ProductsPriceAndInventoriesQueryVariables
        >({
            query: ProductsPriceAndInventoriesDocument,
            variables: { skus },
        });

        await this.updateApolloCache(this.apolloClient.cache);

        this.cache[cacheKey] = {
            data: result.data.products?.items,
            timestamp: now,
        };

        return result.data.products?.items;
    }
}

// This file was automatically generated by graphql-code-generator
// Changes to this file will be lost


import * as Types from '../../../engine/apis/magento2/types.generated.d';

import { gql } from '@apollo/client';
export type PriceAndStockFragment_BundleProduct_Fragment = { __typename?: 'BundleProduct', specialPriceEndDate?: string | null, specialPriceFromDate?: string | null, stocks?: Array<{ __typename?: 'StockDetails', sbiStockCode?: string | null, status?: string | null, quantity?: number | null, availabilityDate?: string | null } | null> | null, priceRange: { __typename?: 'PriceRange', minimumPrice: { __typename?: 'ProductPrice', regularPrice: { __typename?: 'Money', value?: number | null, currency?: Types.CurrencyEnum | null }, discount?: { __typename?: 'ProductDiscount', amountOff?: number | null, percentOff?: number | null } | null, finalPrice: { __typename?: 'Money', value?: number | null, currency?: Types.CurrencyEnum | null } } } };

export type PriceAndStockFragment_ConfigurableProduct_Fragment = { __typename?: 'ConfigurableProduct', specialPriceEndDate?: string | null, specialPriceFromDate?: string | null, stocks?: Array<{ __typename?: 'StockDetails', sbiStockCode?: string | null, status?: string | null, quantity?: number | null, availabilityDate?: string | null } | null> | null, priceRange: { __typename?: 'PriceRange', minimumPrice: { __typename?: 'ProductPrice', regularPrice: { __typename?: 'Money', value?: number | null, currency?: Types.CurrencyEnum | null }, discount?: { __typename?: 'ProductDiscount', amountOff?: number | null, percentOff?: number | null } | null, finalPrice: { __typename?: 'Money', value?: number | null, currency?: Types.CurrencyEnum | null } } } };

export type PriceAndStockFragment_DownloadableProduct_Fragment = { __typename?: 'DownloadableProduct', specialPriceEndDate?: string | null, specialPriceFromDate?: string | null, stocks?: Array<{ __typename?: 'StockDetails', sbiStockCode?: string | null, status?: string | null, quantity?: number | null, availabilityDate?: string | null } | null> | null, priceRange: { __typename?: 'PriceRange', minimumPrice: { __typename?: 'ProductPrice', regularPrice: { __typename?: 'Money', value?: number | null, currency?: Types.CurrencyEnum | null }, discount?: { __typename?: 'ProductDiscount', amountOff?: number | null, percentOff?: number | null } | null, finalPrice: { __typename?: 'Money', value?: number | null, currency?: Types.CurrencyEnum | null } } } };

export type PriceAndStockFragment_GroupedProduct_Fragment = { __typename?: 'GroupedProduct', specialPriceEndDate?: string | null, specialPriceFromDate?: string | null, stocks?: Array<{ __typename?: 'StockDetails', sbiStockCode?: string | null, status?: string | null, quantity?: number | null, availabilityDate?: string | null } | null> | null, priceRange: { __typename?: 'PriceRange', minimumPrice: { __typename?: 'ProductPrice', regularPrice: { __typename?: 'Money', value?: number | null, currency?: Types.CurrencyEnum | null }, discount?: { __typename?: 'ProductDiscount', amountOff?: number | null, percentOff?: number | null } | null, finalPrice: { __typename?: 'Money', value?: number | null, currency?: Types.CurrencyEnum | null } } } };

export type PriceAndStockFragment_SimpleProduct_Fragment = { __typename?: 'SimpleProduct', specialPriceEndDate?: string | null, specialPriceFromDate?: string | null, stocks?: Array<{ __typename?: 'StockDetails', sbiStockCode?: string | null, status?: string | null, quantity?: number | null, availabilityDate?: string | null } | null> | null, priceRange: { __typename?: 'PriceRange', minimumPrice: { __typename?: 'ProductPrice', regularPrice: { __typename?: 'Money', value?: number | null, currency?: Types.CurrencyEnum | null }, discount?: { __typename?: 'ProductDiscount', amountOff?: number | null, percentOff?: number | null } | null, finalPrice: { __typename?: 'Money', value?: number | null, currency?: Types.CurrencyEnum | null } } } };

export type PriceAndStockFragment_VirtualProduct_Fragment = { __typename?: 'VirtualProduct', specialPriceEndDate?: string | null, specialPriceFromDate?: string | null, stocks?: Array<{ __typename?: 'StockDetails', sbiStockCode?: string | null, status?: string | null, quantity?: number | null, availabilityDate?: string | null } | null> | null, priceRange: { __typename?: 'PriceRange', minimumPrice: { __typename?: 'ProductPrice', regularPrice: { __typename?: 'Money', value?: number | null, currency?: Types.CurrencyEnum | null }, discount?: { __typename?: 'ProductDiscount', amountOff?: number | null, percentOff?: number | null } | null, finalPrice: { __typename?: 'Money', value?: number | null, currency?: Types.CurrencyEnum | null } } } };

export type PriceAndStockFragmentFragment = PriceAndStockFragment_BundleProduct_Fragment | PriceAndStockFragment_ConfigurableProduct_Fragment | PriceAndStockFragment_DownloadableProduct_Fragment | PriceAndStockFragment_GroupedProduct_Fragment | PriceAndStockFragment_SimpleProduct_Fragment | PriceAndStockFragment_VirtualProduct_Fragment;

export const PriceAndStockFragmentFragmentDoc = gql`
    fragment PriceAndStockFragment on ProductInterface {
  stocks {
    sbiStockCode
    status
    quantity
    availabilityDate
  }
  priceRange: price_range {
    minimumPrice: minimum_price {
      regularPrice: regular_price {
        value
        currency
      }
      discount {
        amountOff: amount_off
        percentOff: percent_off
      }
      finalPrice: final_price {
        value
        currency
      }
    }
  }
  specialPriceEndDate: special_to_date
  specialPriceFromDate: special_from_date
}
    `;
import { IStoreContext, StoreContext } from './StoreContext';
import React, { useMemo } from 'react';

import { BoutikService } from '../../engine/BoutikService';
import { PricesAndInventoriesCache } from '../../engine/PricesAndInventoriesCache';
import { useAllProductsPricesSSR } from './gatsby';
import { useBoutikService } from '../boutikService';
import { useCustomerAccount } from '../customerAccount';
import { usePageLocalization } from '@sbi-international/boutik.gatsby.plugin.multilocale';

export interface IStoreProviderProps {
    children: React.ReactNode;
}

// FIXME: Sim: Get this mapping from configuration
const localeCodeToStoreViewCodeMapping: { [locale: string]: string } = {
    fr_CA: 'mfp_fr_ca',
    en_CA: 'mfp_en_ca',
    en_US: 'mfp_en_us',
};

const useLatestPrices = (
    boutikService: BoutikService,
    currentStore: string | undefined
): PricesAndInventoriesCache => {
    const allProductsPricesSSR = useAllProductsPricesSSR();
    const { currentUser } = useCustomerAccount();

    const cache = useMemo<PricesAndInventoriesCache>(() => {
        const cache = new PricesAndInventoriesCache(
            boutikService,
            currentStore as string
        );
        cache.setAllProductsPricesSSR(
            allProductsPricesSSR[`magento_${currentStore}`]
        );
        return cache;

        // SL: added currentUser so the prices are updated when the user logs in
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [boutikService, currentStore, allProductsPricesSSR, currentUser]);

    return cache;
};

export const StoreProvider: React.FC<IStoreProviderProps> = ({ children }) => {
    const { boutikService } = useBoutikService();

    const { currentLocale } = usePageLocalization();
    let currentStore: string | undefined = undefined;
    if (currentLocale) {
        currentStore = localeCodeToStoreViewCodeMapping[currentLocale];
        boutikService.storeViewCode = currentStore;
    }

    const pricesAndInventoriesCache = useLatestPrices(
        boutikService,
        currentStore
    );

    const contextValue = useMemo<IStoreContext>(() => {
        return {
            pricesAndInventoriesCache,
            currentStore,
        };
    }, [pricesAndInventoriesCache, currentStore]);

    return (
        <StoreContext.Provider value={contextValue}>
            {children}
        </StoreContext.Provider>
    );
};

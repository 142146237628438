import dayjs from 'dayjs';

import {
    ProductService,
    ProductsInventorySkuStatus,
    ProductsInventoryStatus,
} from '../interfaces/services/productService';
import { ProductOperations } from '../interfaces/operations/productOperations';
import {
    BundleItemOption,
    ProductInterface,
} from '../engine/apis/magento2/types.generated';
import { IProductInventoryStock } from '../engine/PricesAndInventoriesCache';

export type Stock = {
    sbiStockCode?: string | null;
    status?: string | null;
    quantity?: number | null;
    availabilityDate?: string | null;
} | null;

export class ProductServiceImpl implements ProductService {
    private productOperations: ProductOperations;
    private cache: Record<string, { data: any; expiration: number }>;

    constructor(productOperations: ProductOperations) {
        this.productOperations = productOperations;
        this.cache = {};
    }

    async getProductOptionPriceAndInventoryBySkus(
        skus: string[]
    ): Promise<any> {
        const cacheKey = `pricesAndInventories_${skus.join(',')}`;
        const cachedData = this.cache[cacheKey];
        const now = Date.now();

        if (cachedData && cachedData.expiration > now) {
            return cachedData.data;
        }

        const pricesAndInventories =
            await this.productOperations.getPricesAndInventoriesQuery(skus);

        this.cache[cacheKey] = {
            data: pricesAndInventories,
            expiration: now + 5 * 60 * 1000,
        };

        return pricesAndInventories;
    }

    async getProductInventoryStockBySku(
        sku: string,
        currentStore: string
    ): Promise<IProductInventoryStock | null> {
        const cacheKey = `productInventoryStock_${sku}`;
        const cachedData = this.cache[cacheKey];
        const now = Date.now();

        if (cachedData && cachedData.expiration > now) {
            return cachedData.data;
        }

        const inventories =
            await this.productOperations.getPricesAndInventoriesQuery([sku]);

        return inventories ? this.setStock(inventories[0], currentStore) : null;
    }

    async getProductInventoryStatusBySku(
        skus: string[],
        currentStore: string
    ): Promise<ProductsInventorySkuStatus[]> {
        const cacheKey = `inventoryStatus_${skus.join(',')}`;
        const cachedData = this.cache[cacheKey];
        const now = Date.now();

        if (cachedData && cachedData.expiration > now) {
            return cachedData.data;
        }

        const inventories =
            await this.productOperations.getPricesAndInventoriesQuery(skus);

        const pricesAndInventories = inventories
            ? inventories.map((inventory: any): ProductsInventorySkuStatus => {
                  const localStock = this.getLocalStock(
                      inventory?.stocks as Stock[],
                      currentStore
                  );
                  if (
                      localStock?.status == 'in-stock' ||
                      localStock?.status == 'out-of-stock'
                  ) {
                      return {
                          sku: inventory.sku as string,
                          status: localStock?.status,
                          availabilityDate: localStock.availabilityDate,
                      };
                  } else if (
                      localStock?.status == 'preorder' &&
                      dayjs(localStock?.availabilityDate).isSameOrAfter(dayjs())
                  ) {
                      return {
                          sku: inventory.sku as string,
                          status: 'preorder',
                          availabilityDate: localStock.availabilityDate,
                      };
                  } else {
                      return {
                          sku: inventory.sku as string,
                          status: 'out-of-stock',
                          availabilityDate: undefined,
                      };
                  }
              })
            : [];

        this.cache[cacheKey] = {
            data: pricesAndInventories,
            expiration: now + 5 * 60 * 1000,
        };

        return pricesAndInventories;
    }

    extractInventorySkuStatusInOptions(
        bundleItemOptions: BundleItemOption[],
        currentStore: string
    ): ProductsInventorySkuStatus[] {
        const now = dayjs();
        if (bundleItemOptions) {
            return bundleItemOptions.map((option: BundleItemOption) => {
                const product = option.product as ProductInterface;

                const stock = this.getLocalStock(
                    product?.stocks as Stock[],
                    currentStore
                );

                if (
                    stock?.status == 'in-stock' ||
                    stock?.status == 'out-of-stock'
                ) {
                    return {
                        sku: product.sku as string,
                        status: stock?.status,
                        availabilityDate: stock.availabilityDate,
                    };
                } else if (
                    stock?.status == 'preorder' &&
                    dayjs(stock?.availabilityDate).isSameOrAfter(now)
                ) {
                    return {
                        sku: product.sku as string,
                        status: 'preorder',
                        availabilityDate: stock.availabilityDate,
                    };
                } else {
                    return {
                        sku: product.sku as string,
                        status: 'out-of-stock',
                        availabilityDate: undefined,
                    };
                }
            });
        }
        return [];
    }

    extractInventoryInOptions(
        bundleItemOptions: BundleItemOption[],
        currentStore: string
    ): (Stock | undefined)[] {
        if (bundleItemOptions) {
            return bundleItemOptions.map((option: BundleItemOption) => {
                const product = option.product as ProductInterface;
                return this.getLocalStock(
                    product?.stocks as Stock[],
                    currentStore
                );
            });
        }
        return [];
    }

    setStock(
        inventory: any,
        currentStore: string
    ): IProductInventoryStock | null {
        const stockDetail = this.getLocalStock(inventory.stocks, currentStore);

        return stockDetail
            ? {
                  inStock: stockDetail.status
                      ? stockDetail.status != 'out-of-stock'
                      : false,
                  status: (stockDetail.status ??
                      'out-of-stock') as ProductsInventoryStatus,
                  onlyXLeftInStock: stockDetail.quantity ?? undefined,
                  nextAvailableDate: stockDetail.availabilityDate ?? undefined,
                  specialPriceEndDate:
                      inventory.specialPriceEndDate ?? undefined,
                  specialPriceFromDate:
                      inventory.specialPriceFromDate ?? undefined,
              }
            : null;
    }

    getLocalStock(
        stockDetails: Stock[],
        currentStore: string
    ): Stock | undefined {
        const local = currentStore.includes('_ca') ? 'CA' : 'US';
        const stockDetail = stockDetails.find(
            (s: Stock) => s?.sbiStockCode == local
        );
        return stockDetail;
    }
}
